import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
import { useSessionContext } from '@shopware-pwa/composables-next';

const recaptchaState = reactive({
  isInitialized: false,
});

export const useGoogleReCaptcha = () => {
  const { vueApp } = useNuxtApp();
  const sessionContext: any = useSessionContext();

  const siteKey =
    sessionContext.sessionContext.value?.extensions?.captchas?.googleReCaptchaV3
      ?.siteKey || '';

  // Create a flag to track if VueReCaptcha is already initialized
  if (!recaptchaState.isInitialized && siteKey) {
    vueApp.use(VueReCaptcha, {
      siteKey: siteKey,
      loaderOptions: {
        autoHideBadge: true,
        useEnterprise: true,
      },
    });

    recaptchaState.isInitialized = true;
  }

  const recaptchaInstance = useReCaptcha();

  const recaptcha = async (action: string) => {
    await recaptchaInstance?.recaptchaLoaded();
    return await recaptchaInstance?.executeRecaptcha(action);
  };

  const isRecaptchaActive = () => {
    return sessionContext.sessionContext.value?.extensions?.captchas
      ?.googleReCaptchaV3?.isActive;
  };

  return {
    recaptcha,
    isRecaptchaActive,
  };
};
